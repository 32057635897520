// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-digital-marketing-content-marketing-js": () => import("./../../../src/pages/service/digital-marketing/content-marketing.js" /* webpackChunkName: "component---src-pages-service-digital-marketing-content-marketing-js" */),
  "component---src-pages-service-digital-marketing-email-marketing-js": () => import("./../../../src/pages/service/digital-marketing/email-marketing.js" /* webpackChunkName: "component---src-pages-service-digital-marketing-email-marketing-js" */),
  "component---src-pages-service-digital-marketing-js": () => import("./../../../src/pages/service/digital-marketing.js" /* webpackChunkName: "component---src-pages-service-digital-marketing-js" */),
  "component---src-pages-service-digital-marketing-pay-per-click-js": () => import("./../../../src/pages/service/digital-marketing/pay-per-click.js" /* webpackChunkName: "component---src-pages-service-digital-marketing-pay-per-click-js" */),
  "component---src-pages-service-digital-marketing-social-media-marketing-js": () => import("./../../../src/pages/service/digital-marketing/social-media-marketing.js" /* webpackChunkName: "component---src-pages-service-digital-marketing-social-media-marketing-js" */),
  "component---src-pages-service-seo-js": () => import("./../../../src/pages/service/seo.js" /* webpackChunkName: "component---src-pages-service-seo-js" */),
  "component---src-pages-service-seo-off-page-seo-js": () => import("./../../../src/pages/service/seo/off-page-seo.js" /* webpackChunkName: "component---src-pages-service-seo-off-page-seo-js" */),
  "component---src-pages-service-seo-on-page-seo-js": () => import("./../../../src/pages/service/seo/on-page-seo.js" /* webpackChunkName: "component---src-pages-service-seo-on-page-seo-js" */),
  "component---src-pages-service-seo-seo-copywriting-js": () => import("./../../../src/pages/service/seo/seo-copywriting.js" /* webpackChunkName: "component---src-pages-service-seo-seo-copywriting-js" */),
  "component---src-pages-service-web-development-corporate-website-js": () => import("./../../../src/pages/service/web-development/corporate-website.js" /* webpackChunkName: "component---src-pages-service-web-development-corporate-website-js" */),
  "component---src-pages-service-web-development-ecommerce-development-js": () => import("./../../../src/pages/service/web-development/ecommerce-development.js" /* webpackChunkName: "component---src-pages-service-web-development-ecommerce-development-js" */),
  "component---src-pages-service-web-development-js": () => import("./../../../src/pages/service/web-development.js" /* webpackChunkName: "component---src-pages-service-web-development-js" */),
  "component---src-pages-service-web-development-landings-and-promo-sites-js": () => import("./../../../src/pages/service/web-development/landings-and-promo-sites.js" /* webpackChunkName: "component---src-pages-service-web-development-landings-and-promo-sites-js" */),
  "component---src-pages-service-web-development-startup-development-js": () => import("./../../../src/pages/service/web-development/startup-development.js" /* webpackChunkName: "component---src-pages-service-web-development-startup-development-js" */),
  "component---src-pages-service-web-development-website-development-js": () => import("./../../../src/pages/service/web-development/website-development.js" /* webpackChunkName: "component---src-pages-service-web-development-website-development-js" */),
  "component---src-pages-we-hire-js": () => import("./../../../src/pages/we-hire.js" /* webpackChunkName: "component---src-pages-we-hire-js" */)
}

